// extracted by mini-css-extract-plugin
export var screen = "film-post-module--screen--2TFue";
export var backLink = "film-post-module--backLink--3SZ1y";
export var backArrow = "film-post-module--backArrow--13SdU";
export var content = "film-post-module--content--2-QI1";
export var turnButton = "film-post-module--turnButton--EziOW";
export var buttonAnime = "film-post-module--buttonAnime--2wTBb";
export var centerContent = "film-post-module--centerContent--2k06S";
export var imgBlock = "film-post-module--imgBlock--uVTNd";
export var imgBlockInner = "film-post-module--imgBlockInner--29quS";
export var borderBlock = "film-post-module--borderBlock--2WzN9";
export var pageNumber = "film-post-module--pageNumber--1Ck2x";
export var turnButtonSpBlock = "film-post-module--turnButtonSpBlock--1j8N0";
export var turnButtonSp = "film-post-module--turnButtonSp--3pcLc";
export var parent = "film-post-module--parent--12NMj";
export var child = "film-post-module--child--3oHZj";